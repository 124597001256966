import { graphql } from "gatsby";
import React from "react";
import * as styles from "../../styles/product.module.scss";
import Img from "gatsby-image";
import BlockContent from "@sanity/block-content-to-react";
// import styles from "../../styles/product.module.scss";

interface Props {
  description: any;
  logo: any;
  alt: string;
}

export const ProductFeature: React.FC<Props> = ({ description, logo, alt }) => {
  return (
    <div className={styles.featureWrapper}>
      {/* <div className={styles.featureContainer}> */}
      <div>
        <Img
          fluid={logo}
          alt={alt}
          className={styles.featureImg}
          style={{ marginRight: "40px" }}
        />
      </div>
      <div>
        <BlockContent blocks={description} />
      </div>
    </div>
  );
};
