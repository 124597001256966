import { graphql } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import * as styles from "../styles/product.module.scss";
import { usePageContext } from "../components/Context/pageContext";
import Img from "gatsby-image";
import BlockContent from "@sanity/block-content-to-react";
import { ProductFeature } from "../components/Product/productFeature";
import { ProductRef } from "../components/Product/productRef";
import Swiper from "react-id-swiper";
import "swiper/swiper.scss";
import { ProductGallery } from "../components/Product/productGallery";
import { BurgerMenu } from "../components/Header/burgerMenu";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useWindowSize } from "../components/Utils/useWindowSize";
import GoBack from "../components/Utils/backButton";
import GoHome from "../components/Utils/homeButton";
import "swiper/components/effect-fade/effect-fade.scss";
import SEO from "../components/seo";

const ProductTemplate = ({ data }: any) => {
  const { product, refs } = data;
  const [size, setSize] = useState(0);
  const { lang } = usePageContext();
  const raw = "_raw" + lang.charAt(0).toUpperCase() + lang.slice(1);
  const swiperRef = useRef<any>(null);
  const dimensions = useWindowSize();

  const params = {};

  const sizeSlides: any = [];
  product.sizes.map((size: any, index: number) =>
    sizeSlides.push(
      <div style={{ display: "flex", margin: "0 auto" }} key={`slide-${index}`}>
        <Img
          fixed={size.localeImage.image.asset.fluid}
          alt={size.localeImage[lang].alt}
        />
      </div>
    )
  );

  const handleClick = () => {
    const element = document.getElementById("navvMenu");
    if (element) element.classList.toggle("inactive");
  };

  return (
    <Layout theme={"light"} withoutLogo={true}>
      <SEO
        title={product?.seo?.title && product?.seo?.title[lang]}
        description={product?.seo?.desc && product?.seo?.desc[lang]}
        image={product?.seo?.image?.asset?.url}
      />
      <div className={`margin ${styles.shopPageWrapper}`}>
        <GoBack />
        <div className={styles.productWrapper}>
          <div className={styles.mobileTitle}>
            <GoHome />
            <div className={styles.pageTitle}>
              <p>XORUS</p>
              <p> {product.title}</p>
            </div>
            <div id="burgerMenu" className={styles.burgerPosition}>
              <BurgerMenu theme={"light"} onClick={handleClick} />
            </div>
          </div>

          <div className={styles.descWrapper}>
            <div className={styles.pageTitle}>XORUS {product.title}</div>
            <div className={`${styles.text} ${styles.prodText}`}>
              <BlockContent
                blocks={product.description[raw]}
                style={{ marginBottom: "30px" }}
              />
              {/* <br />
              <BlockContent blocks={product.techDescription[raw]} /> */}
            </div>
          </div>

          <div className={styles.sizeImgWrapper}>
            <div className="relative">
              <img src={"/gouttes.svg"} style={{ width: "100%" }} alt="xorus" />

              <div className={styles.swiperWrapper}>
                <Swiper
                  on={{
                    slideChange: () => {
                      setSize(
                        swiperRef?.current?.swiper?.activeIndex %
                          product.sizes.length
                      );
                    },
                  }}
                  ref={swiperRef}
                  effect={"fade"}
                  slidesPerView={1}
                  spaceBetween={1000}
                  containerClass={"overflow"}
                >
                  {product.sizes
                    .sort((a: any, b: any) => a.size - b.size)
                    .map(
                      (size: any) => (
                        <img
                          src={size.localeImage.image.asset.url}
                          className={styles.sizeImg}
                          alt={size.localeImage[lang].alt}
                          style={{
                            transform: `rotate(-6deg) scale(${
                              size.size /
                              Math.max(...product.sizes.map((a: any) => a.size))
                            })`,
                          }}
                        />
                      )
                      // {/* <Img
                      //     fluid={size.localeImage.image.asset.fluid}
                      //     alt={size.localeImage[lang].alt}
                      //     style={{ width: "100%", height: "100%" }}
                      //   /> */}
                      // </div>
                    )}
                  {/* {sizeSlides} */}
                </Swiper>
              </div>
              <div className={styles.swiperCommandsWrapper}>
                <div className={styles.swiperCommands}>
                  <button
                    onClick={() => swiperRef.current.swiper.slidePrev()}
                    className="swiperButton wiggleLeft"
                    style={{
                      visibility: swiperRef?.current?.swiper?.isBeginning
                        ? "hidden"
                        : "visible",
                    }}
                  >
                    <img
                      src="/btn-prev.svg"
                      alt="swiper-prev"
                      className={styles.swiperButtonPrev}
                    />
                  </button>
                  {/* // style={{ backgroundImage: `url(${btnPrev})` }}
                      style={{ backgroundImage: `url(/btn-prev.svg)` }} */}
                  {/* ></im> */}
                  <div className={styles.carouselTitle}>
                    {product.title +
                      " " +
                      product.sizes.sort((a: any, b: any) => a.size - b.size)[
                        size
                      ].size}
                  </div>
                  <button
                    onClick={() => swiperRef.current.swiper.slideNext()}
                    className="swiperButton wiggleRight"
                    style={{
                      visibility: swiperRef?.current?.swiper?.isEnd
                        ? "hidden"
                        : "visible",
                    }}
                  >
                    <img
                      src="/btn-next.svg"
                      alt="swiper-next"
                      className={styles.swiperButtonNext}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className={styles.sectionWrapper}>
          <div className={styles.title}>Features</div>
          {product.features.map((feature: any, index: number) => (
            <React.Fragment key={index + "-feature"}>
              <ProductFeature
                description={feature.description[raw]}
                logo={feature.logo.image.asset.fluid}
                alt={feature.logo[lang].alt}
              />
            </React.Fragment>
          ))}
        </div>
        <Divider />
        <div className={styles.sectionWrapper}>
          <div className={styles.rangeMenuWrapper}>
            <div className={`${styles.title} ${styles.rangeTitle}`}>
              Color Range
            </div>

            <div className={styles.rangeMenuPart}>
              <p className={`${styles.text} ${styles.productNumber}`}>
                {refs.edges.filter(
                  (ref: any) => ref.node.size === product.sizes[size].size
                ).length > 0
                  ? refs.edges.filter(
                      (ref: any) => ref.node.size === product.sizes[size].size
                    ).length + " products"
                  : refs.edges.filter(
                      (ref: any) => ref.node.size === product.sizes[size].size
                    ).length + " product"}
              </p>

              <div className={styles.rangeMenu}>
                <div
                  className={`${styles.rangeButton} ${styles.flexRow}`}
                  style={{ cursor: "default" }}
                >
                  <div>
                    {product.title}
                    {"\u00A0"}
                    {product.sizes[size].size}
                    {"\u00A0"}
                  </div>

                  {/* <div></div> */}
                  <img
                    src="/vector.svg"
                    className={styles.arrow}
                    alt="menu-arrow"
                  />
                  {/* <MdKeyboardArrowDown className={styles.arrow} />
                  </img> */}
                </div>
                <div className={styles.rangeSubMenu}>
                  {product.sizes.map(
                    (_: any, index: number) =>
                      index != size && (
                        <div key={index + "-size"}>
                          <button
                            className={styles.rangeButton}
                            onClick={() => setSize(index)}
                          >
                            <div className={styles.flexRow}>
                              <div>
                                {product.title}
                                {"\u00A0"}
                                {product.sizes[index].size}
                              </div>
                            </div>
                          </button>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rangeItemsWrapper}>
            {dimensions.width >= 920 ? (
              refs.edges
                .filter(
                  (edge: any) => edge.node.size == product.sizes[size].size
                )
                .sort((a: any, b: any) =>
                  a.node.color
                    .toLowerCase()
                    .localeCompare(b.node.color.toLowerCase())
                )
                .map((ref: any, index: number) => (
                  <div className={styles.rangeItem} key={index + "-ref"}>
                    <ProductRef
                      url={ref.node.localeImage[0].image.asset.url}
                      alt={ref.node.localeImage[0][lang].alt}
                      title={ref.node.color}
                      description={ref.node.description[raw]}
                      scale={
                        ref.node.size /
                        Math.max(...product.sizes.map((a: any) => a.size))
                      }
                    />
                  </div>
                ))
            ) : (
              <>
                {refs.edges.filter(
                  (edge: any) => edge.node.size == product.sizes[size].size
                ).length > 0 && (
                  <img
                    src="/gouttes.svg"
                    className={styles.rangeBackground}
                    style={{ marginBottom: "0" }}
                    alt="xorus water"
                  />
                )}

                <Swiper>
                  {refs.edges
                    .filter(
                      (edge: any) => edge.node.size == product.sizes[size].size
                    )
                    .sort((a: any, b: any) =>
                      a.node.color
                        .toLowerCase()
                        .localeCompare(b.node.color.toLowerCase())
                    )
                    .map((ref: any, index: number) => (
                      <div
                        style={{ display: "flex", margin: "0 auto" }}
                        key={`refslide-${index}`}
                      >
                        <ProductRef
                          url={ref.node.localeImage[0].image.asset.url}
                          alt={ref.node.localeImage[0][lang].alt}
                          title={ref.node.color}
                          description={ref.node.description[raw]}
                          swipe={true}
                          scale={
                            ref.node.size /
                            Math.max(...product.sizes.map((a: any) => a.size))
                          }
                        />
                      </div>
                    ))}
                </Swiper>
              </>
            )}
          </div>
        </div>
        <Divider />
        <div className={styles.galleryWrapper}>
          <div className={styles.title}>Gallery</div>
          <ProductGallery images={product.gallery} />
        </div>
      </div>
    </Layout>
  );
};

export default ProductTemplate;

const Divider = styled.hr`
  height: 0;
  border: 1px solid rgba(238, 221, 36, 0.2);
  margin-bottom: 90px;
`;

export const query = graphql`
  query($id: String) {
    product: sanityProduct(id: { eq: $id }) {
      description {
        _rawEn
        _rawEs
      }
      techDescription {
        _rawEn
        _rawEs
      }
      features {
        description {
          _rawEn
          _rawEs
        }
        logo {
          en {
            alt
          }
          es {
            alt
          }
          image {
            asset {
              # fluid(maxHeight: 3000, maxWidth: 6000) {
              #   ...GatsbySanityImageFluid
              fluid(maxWidth: 1200, maxHeight: 706) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      sizes {
        localeImage {
          en {
            alt
          }
          es {
            alt
          }
          image {
            asset {
              url
              # fluid(maxWidth: 1200, maxHeight: 1000) {
              #   ...GatsbySanityImageFluid

              # fixed(width: 800, height: 500) {
              #   ...GatsbySanityImageFixed
              # }
            }
          }
        }
        size
      }
      gallery {
        en {
          alt
        }
        es {
          alt
        }
        image {
          asset {
            url
            # fixed(width: 800, height: 500) {
            #   ...GatsbySanityImageFixed
            # fluid(maxWidth: 1200, maxHeight: 1000) {
            #   ...GatsbySanityImageFluid
            # }
          }
        }
      }
      title
      seo {
        desc: seoDescription {
          en
          es
        }
        image: seoImage {
          asset {
            url
          }
        }
        title: seoTitle {
          en
          es
        }
      }
    }
    refs: allSanityRef(filter: { product: { id: { eq: $id } } }) {
      edges {
        node {
          localeImage {
            en {
              alt
            }
            es {
              alt
            }
            image {
              asset {
                # fixed(width: 345, height: 68) {
                #   ...GatsbySanityImageFixed

                url
                # fluid(maxWidth: 1200, maxHeight: 1000) {
                #   ...GatsbySanityImageFluid
                # }
              }
            }
          }
          description {
            _rawEn
            _rawEs
          }
          color
          size
        }
      }
    }
  }
`;
