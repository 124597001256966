import React, { useEffect, useRef, useState } from "react";
import * as styles from "../../styles/productGallery.module.scss";
import Img from "gatsby-image";
// import btnPrev from "../../../static/btn-prev.svg";
// import btnNext from "../../../static/btn-next.svg";
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdNoEncryption,
} from "react-icons/md";

// Import Swiper React components
import Swiper from "react-id-swiper";
// import SwiperCore, { Navigation, EffectFade } from "swiper";
import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";
// import "swiper/components/effect-fade/effect-fade.less";

// Import Swiper styles
import { usePageContext } from "../Context/pageContext";

interface Props {
  images: any;
}

export const ProductGallery: React.FC<Props> = ({ images }) => {
  const { lang } = usePageContext();
  const swiperRef = useRef(null);
  const [index, setIndex] = useState<number>(0);
  return (
    // <div className={styles.galleryWrapper}>
    <div>
      <div className={styles.swiperWrapper}>
        <Swiper
          on={{
            slideChange: () => {
              setIndex(swiperRef?.current?.swiper?.activeIndex % images.length);
            },
          }}
          ref={swiperRef}
          // autoHeight={true}
          pagination={{
            el: ".swiper-pagination",
            type: "fraction",
          }}
          // slideClass={"heightAuto"}
        >
          {images.map((image: any, index: number) => (
            // <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={image.image.asset.url}
              alt={image[lang].alt}
              key={`gallery-${index}`}
              style={{ marginBottom: 0, objectFit: "contain" }}
            />
            // </div>
            // <Img
            //   fixed={image.image.asset.fixed}
            //   alt={image[lang].alt}
            //   style={{ width: "100%", height: "100%" }}
            // />
          ))}
        </Swiper>
      </div>
      <div className={styles.commandsWrapper}>
        <button
          onClick={() => swiperRef.current.swiper.slidePrev()}
          className="swiperButton"
          style={{
            visibility: swiperRef?.current?.swiper?.isBeginning
              ? "hidden"
              : "visible",
          }}
        >
          <img
            src="/btn-prev.svg"
            alt="prev"
            className={styles.swiperButtonPrev}
            // onClick={() => swiperRef.current.swiper.slidePrev()}
            // style={{
            //   visibility: swiperRef.current?.swiper?.isBeginning
            //     ? "hidden"
            //     : "visible",
            // }}
          />
        </button>

        <div>{`${index + 1}/${images.length}`}</div>
        <button
          onClick={() => swiperRef.current.swiper.slideNext()}
          className="swiperButton"
          style={{
            visibility: swiperRef?.current?.swiper?.isEnd
              ? "hidden"
              : "visible",
          }}
        >
          <img
            src="/btn-next.svg"
            alt="next"
            className={styles.swiperButtonNext}
          />
        </button>
      </div>
    </div>
  );
};
